import React from "react"
import { graphql } from "gatsby"
import SEO from "../utils/seo"
import BlogRoll from "../components/blogRoll/blogRoll"

const Blog = ({ data }) => {

  return (

    <>
      <SEO
        title={data.wpPage.seo.title || data.wpPage.title}
        description={
          data.wpPage.seo.metaDesc ||
          data.wpPage.excerpt ||
          data.wpPage.description
        }
        canonical={data.wpPage.uri}
        article={false}
      />

      <BlogRoll title={data.wpPage.title} sticky={true} />

    </>

  )

}

export default Blog

export const pageQuery = graphql`
  query{
    wpPage(title: {eq: "Blog"}) {
      ...pageFragments
      seo {
        title
        metaDesc
      }
    }
  }
`